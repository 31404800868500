<template>
  <v-alert v-if="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" color="info" text dense>
    <p v-t="'upgradeInformation'" class="font-weight-semibold mb-1" />
    <a
      :href="$i18n.locale === 'pl' ? 'https://lightapply.com/pl/cennik/' : 'https://lightapply.com/en/pricing/'"
      target="_blank"
      >{{ $i18n.locale === 'pl' ? 'https://lightapply.com/pl/cennik/' : 'https://lightapply.com/en/pricing/' }}</a
    >
  </v-alert>
</template>
<script>
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  setup() {
    return {
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
